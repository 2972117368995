import { Box } from "@mui/material";
import { ReactNode } from "react";
import Main from "./main";

type Props = {
    children: ReactNode
}

export default function ContractLayout({ children }: Props){
    return (
        <>
            <Box
                sx={{
                    minHeight: 1,
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                }}
            >
                <Main>{children}</Main>
            </Box>
        </>
    )
}