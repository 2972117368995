import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';

import { IconButton, InputAdornment, Stack, Switch, Typography } from '@mui/material';
import { updateUserProfile, useGetUserById } from 'src/api/user';
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { IUserProfileUpdateRequest } from 'src/types/user';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onOpenNotificationHelper: VoidFunction;
};

export default function UserQuickEditForm({ open, onClose, onOpenNotificationHelper }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const { currentUser }: any = useGetUserById(user?.id);
  const showPassword = useBoolean();
  const permission = useBoolean();

  useEffect(() => {
    if(currentUser){
        const values = getValues();
        const fieldsNames = Object.keys(values) as unknown as any[];

        fieldsNames.forEach(field => {
            setValue(field, currentUser[field], { shouldValidate: true })
        });
    }

    notificationsPermission().then((value) => {
      permission.setValue(value);
    });
  }, [currentUser]);
  
  const newUserSchema: any = Yup.object().shape({
    id: Yup.number().nullable(),
    name: Yup.string(),
    email: Yup.string().email('Precisa ser um E-mail válido'),
    phone: Yup.string(),
    username: Yup.string(),
    document: Yup.string().optional().nullable(),
    newPassword: Yup.string().nullable()
      .min(6, 'Senha precisa ter no minimo 6 caracteres'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'As senhas precisam ser iguais'),
  });

  const defaultValues = useMemo(
    () => ({
      id: currentUser?.id || null,
      name: currentUser?.name || '',
      email: currentUser?.email || '',
      phone: currentUser?.phone || '',
      username: currentUser?.username || '',
      document: currentUser?.document || '',
      newPassword: '',
      confirmNewPassword: '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(newUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const notificationsPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();

    return permission === "granted";
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    const request = new IUserProfileUpdateRequest(data);
    try {
      await updateUserProfile(request);
      enqueueSnackbar('Usuário atualizado com sucesso!');
      reset();
      onClose();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Perfil</DialogTitle>

        <DialogContent>
          <Typography variant="subtitle2">
            Informações
          </Typography>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />

            <RHFTextField name="name" label="Nome" />
            <RHFTextField name="email" label="Email" />
            <RHFTextField name="phone" label="Telefone" mask="(99) 99999-9999" masked/>
            <RHFTextField name="username" label="Usuário" />
            <RHFTextField name="document" label="CPF" mask="999.999.999-99" masked/>
            
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />

            <RHFTextField
              name="newPassword"
              label="Nova senha"
              type={showPassword.value ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={showPassword.onToggle} edge="end">
                      <Iconify icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                <Stack component="span" direction="row" alignItems="center">
                  <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Senha precisa ser no minimo
                  6+
                </Stack>
              }
            />

            <RHFTextField
              name="confirmNewPassword"
              type={showPassword.value ? 'text' : 'password'}
              label="Confirmar nova senha"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={showPassword.onToggle} edge="end">
                      <Iconify icon={showPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </Box>
          <Typography variant="subtitle2" sx={{ marginTop: "18px" }}>
            Notificações
          </Typography>
          
          <Stack
            direction="row"
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction="row" alignItems={"center"} spacing={2}>
              Permissão das notificações
            </Stack>
            <Switch
              name="notificationPermission"
              checked={permission.value}
              onChange={(event: any, checked: boolean) => {
                notificationsPermission().then((value: boolean) => {
                  permission.setValue(value);
                });
              }}
            />
          </Stack>
          <Box>
            <Typography
              style={{
                textDecoration: 'undeline',
                cursor: "pointer"
              }}
              variant="caption"
              onClick={() => {
                onOpenNotificationHelper();
              }}
            >
              Como ativar as notificações?
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Atualizar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
