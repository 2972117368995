import { useEffect } from "react";

import { useRouter } from "src/routes/hooks";

import { useAuthContext } from "src/auth/hooks";

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";
import { Stack } from "@mui/system";

import { useForm } from "react-hook-form";
import { Organization } from "src/auth/types";
import { RHFAutocomplete } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { paths } from "src/routes/paths";
import { USER_PERMISSIONS } from "src/types/permissions";
import { DefaultOrganization } from "src/types/saasOrganization";

export default function SelectOrganizationView() {
  const { 
    user, 
    organizations, 
    listOrganization, 
    checkDefaultOrganization, 
    selectOrganization,
    permissions
  } = useAuthContext();

  const router = useRouter();

  const initialize = async () => {

    if (user) {
      await listOrganization?.();
    }

    if (organizations) {
      const redirectUrl = await checkDefaultOrganization?.();

      if (!paths.listOrgs.endsWith(redirectUrl)) {
        router.push(redirectUrl);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, [user]);

  const handleListItemClick = async (org: Organization) => {

    selectOrganization(org);

    if (org.id === -1) {
      router.push(paths.dashboard.rootAdmin);
      return
    }

    let dashboardPermission = permissions?.filter((permission) => permission === USER_PERMISSIONS.DASHBOARD_VIEW)[0];
    let machinePermission = permissions?.filter((permission) => permission === USER_PERMISSIONS.MACHINES_VIEW)[0];

    if(dashboardPermission){
      router.push(paths.dashboard.root);
      return;
    }

    if(machinePermission){
      router.push(paths.dashboard.machine.list);
      return;
    }

    router.push(paths.dashboard.root);

    
  };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Entrar no painel</Typography>
    </Stack>
  );

  const methods = useForm({
    defaultValues: { organization: DefaultOrganization }
  });

  const {
    handleSubmit
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const organization: Organization = {
      description: data.organization?.description,
      id: data.organization?.id,
      logoPath: data.organization?.logoPath,
      name: data.organization?.name,
    };

    if(organization.id === 0){
      return;
    }

    handleListItemClick(organization);
  });

  const list = (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <RHFAutocomplete
          id={"select-organization"}
          label="Selecione a organização"
          name="organization"
          options={organizations ? organizations : []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => {
            if(typeof option === 'string'){
              return option;
            }

            return option.name;
          }}
          renderOption={(props, option, state, ownerState) => {
            return (
              <ListItem key={option.id}  alignItems="flex-start" disablePadding {...props}>
                <ListItemButton onClick={(event) => handleListItemClick(option)}>
                  <ListItemAvatar>
                    <Avatar alt={option.name} src={option.logoPath} />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="h6">{option.name}</Typography>
                    <Typography variant="body2">{option.description}</Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          }}
        />
      </FormProvider>
    </>
  );

  return (
    <>
      {renderHead}
      {list}
    </>
  );
}
