
export default function applyMask(value: string, mask?: string, ) {
    if(!mask || !value){
        return value;
    }

    let maskTest = mask.replace(/\D/g, '');

    if(/[^9]/.test(maskTest)){
      console.error(`Máscara inválida utilizada, favor verificar. ${mask}`);
      return value;
    }
    
    value = value.replace(/\D/g, "");
    const valueArray = value.split('');
    const maskArray = mask.split('');
    let result = '';
  
    for (let i = 0, j = 0; i < maskArray.length && j < valueArray.length; i++) {
        if (maskArray[i] === '9') {
          // Substitui '9' pelo próximo dígito no valor, se existir
          result += valueArray[j] || '_';
          j += 1;
        } else if (valueArray[j] && valueArray[j] === maskArray[i]) {
          // Mantém o caractere da máscara se o valor já foi preenchido
          result += valueArray[j];
          j += 1;
        } else {
          // Permite a remoção de caracteres
          if (result.charAt(result.length - 1) !== maskArray[i]) {
            result += maskArray[i];
          } else {
            i--; // Reconsidera o caractere da máscara na próxima iteração
          }
        }
      }
  
    return result;
  }

export const DefaultMasksValues: DefaultMasks = {
    phone: "(99) 99999-9999",
    cpf: "999.999.999-99",
    document: "999.999.999-99",
}

interface DefaultMasks {
    [key: string]: string;
}

export const DefaultMasksFieldMapper = (field: string) => {
    let mask = undefined
    Object.keys(DefaultMasksValues).forEach((key) => {
        if(field === key){
            mask = DefaultMasksValues[key]
        }
    });

    return mask;
}