import { useSnackbar } from "notistack";
import { ReactNode } from "react";
import { Navigate } from "react-router";
import { useAuthContext } from "src/auth/hooks";

type Props = {
    permissions?: string[],
    component: ReactNode,
    needAdmin?: boolean
}

export default function RoleBasedPage({ permissions: pagePermissions, component, needAdmin = false }: Props){
    const { permissions, user } = useAuthContext();
    
    const { enqueueSnackbar } = useSnackbar();

    const hasPermissions = pagePermissions?.every(
      el => permissions?.includes(el)
    ) || permissions?.length == 0;

    const haveAdminAccess = user && user.isAdministrator;

    if(!user || !permissions){
        return (
            <></>
        );
    }

    if(needAdmin && !haveAdminAccess){
        enqueueSnackbar("Você não possui permissões para acessar esta página.", { variant: "error" });

        return (
            <Navigate to="/"/>
        );
    }
    
    if(!haveAdminAccess && !hasPermissions){
        enqueueSnackbar("Você não possui permissões para acessar esta página.", { variant: "error" });

        return (
            <Navigate to="/"/>
        );
    }

    return (
        <>
            {component}
        </>
    );
}