export function applyCurrency(txValue: string) {
    let value = txValue;
    
    if (!value) {
        return;
    }
    
    let _txValue = txValue.toString();
    
    value = _txValue;
    value = value.replace("-", "");
    value = value.replace("R$", "");
    value = value.replace(" ", "");
    value = value.replace(",", "");
    value = value.replace(".", "");
    value = value.replace(" ", "");

    let number = Number.parseFloat((Number.parseFloat(value) / 100).toFixed(2));
    
    let currency = number.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    if (currency.indexOf("NaN") > 0) {
      currency = "0,00"
    }

    if(_txValue.indexOf("-") >= 0 && _txValue.indexOf("+") <= 0){
      currency.replace("+", "");
      currency = currency.substring(0, 2) + " -" + currency.substring(3, currency.length);
    }

    if(_txValue.indexOf("+") >= 0 && _txValue.indexOf("-") <= 0){
      currency.replace("-", "");
      currency = currency.substring(0, 2) + " +" + currency.substring(3, currency.length);
    }

    _txValue = currency.slice(3, currency.length);

    return _txValue;
  }

export function removeCurrency(txValue: string) {
  if(!txValue){
    return
  }
  

  let _txValue = txValue.toString();

  _txValue = _txValue.replace(/[^0-9]/g, '');

  const numero = parseFloat(_txValue) / 100;

  return numero;
}