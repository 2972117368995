import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { useSettingsContext } from 'src/components/settings';

import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useGetContract } from 'src/api/contract';
import NeedSignContractDialog from 'src/auth/context/contract/need-sign-contract-dialog';
import SignContractDialog from 'src/auth/context/contract/sign-contract-dialog';
import { useAuthContext } from 'src/auth/hooks';
import { setupNotifications } from 'src/components/firebase/firebase';
import useVisibilityChange from 'src/hooks/use-visibilty-change';
import { useRouter } from 'src/routes/hooks';
import { CONTRACT_URL } from 'src/types/contract';
import Header from './header';
import Main from './main';
import NavHorizontal from './nav-horizontal';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();

  const refresh = useBoolean();

  const openContract = useBoolean();

  const { user, role } = useAuthContext();

  const { contract } = useGetContract(refresh.value);
  
  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const { saveFirebaseToken, authenticated } = useAuthContext();

  const isForeground = useVisibilityChange();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const router = useRouter();

  useEffect(() => {
    if(authenticated){
      setupNotifications(isForeground, enqueueSnackbar, closeSnackbar, saveFirebaseToken, router);
    }

    sessionStorage.setItem(CONTRACT_URL, contract?.contractUrl);

    if(!user && !contract){
      return;
    }

    if(user?.isAdministrator){
      return;
    }

    if(contract && contract.contractUrl){
      const open = contract.signed != true;
      openContract.setValue(open);
    }

  }, [contract]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
        { (contract && role == "Administrador") ?
          <SignContractDialog 
            open={openContract.value} 
            url={contract.contractUrl}
          /> : 
          <NeedSignContractDialog
            open={openContract.value}
          />
        }
      </Box>
    </>
  );
}
