export type ContractContextType = {
    assigned: boolean;
    loading: boolean;
    onAccepted: (status: boolean) => void;
    contract: any;
    onByPass: () => void;
    getContract: () => void;
    checkContract: () => void;
}

export type ContractStateType = {
    assigned: boolean;
    loading: boolean;
    contract: any;
}

export const CONTRACT_URL = 'contractUrl';