
import { useSettingsContext } from "src/components/settings";
import { useResponsive } from "src/hooks/use-responsive";

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function CouponLayout({ children }: Props) {
    const settings = useSettingsContext();

    const lgUp = useResponsive('up', 'lg');

    return (
        <>
            {children}
        </>
    )
}