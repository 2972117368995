export const register = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
                console.error('Error registering service worker:', error);
            });
    }
} 

export const unregister = (enqueueSnackbar: any) => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .getRegistration()
            .then((registration) => {
                if (registration) {
                    registration.pushManager.getSubscription().then(subscription => {
                        if (subscription) {
                            subscription.unsubscribe().then(() => {
                                enqueueSnackbar('Notificações push desativadas.', { variant: 'success' });
                                console.log('Notificações push desativadas.');
                            }).catch(error => {
                                console.error('Erro ao desinscrever notificações push:', error);
                            });
                        }
                    });
                }
            })
            .catch((error) => {
                console.error('Error registering service worker:', error);
            });
    }   
}