import { useMemo } from "react";
import { accessEndpoints } from "src/utils/axios";
import { useCustomApiFetcher } from "src/utils/fetcherCustomHook";

export function useGetContract(refresh: boolean) {
    const url = accessEndpoints.organization.contract;

    const { data, isLoading, error } = useCustomApiFetcher(url, {}, "GET", refresh);

    const memoizedValue = useMemo(() => ({
        contract: data ? data : undefined,
        isLoading,
        error
    }), [data, isLoading, error]);
    
    return memoizedValue;
}