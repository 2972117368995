import { Organization } from 'src/auth/types';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 * Date.now() - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    setSession(undefined, undefined);

    // window.location.href = '/';
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (
  accessToken: string | undefined, 
  refreshToken: string | undefined
) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    setCookie('accessToken', accessToken, 365);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    
  } else {
    sessionStorage.removeItem('accesssToken');
    eraseCookie('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }

  if (refreshToken) {
    sessionStorage.setItem('refreshToken', refreshToken);
    setCookie('refreshToken', refreshToken, 365);
  } else {
    sessionStorage.removeItem('refreshToken');
    eraseCookie('refreshToken');
  }
};

export const setOrganization = (organization: Organization | null) => {
  if (organization?.id) {
    axios.defaults.headers.common.organizationId = organization.id;
  } else {
    delete axios.defaults.headers.common.organizationId;
  }
};

export const setCookie = (name: string, value: string, days: number) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const getCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return undefined;
}

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}