import { useMemo } from 'react';

import { IUser, IUserCount, IUserItem, IUserListResponse, IUserProfileUpdateRequest, IUserRole, IUserSaveRequest, IUserTableFilters, IUserUpdateRequest, UsersListResponse } from 'src/types/user';
import axiosInstance, { accessEndpoints } from 'src/utils/axios';
import { useCustomApiFetcher } from 'src/utils/fetcherCustomHook';

// ----------------------------------------------------------------------
//useGetUserList
//useGetRoleList
//useGetUserById

export function useGetUserList(page: number, limit: number, advancedSearch: IUserTableFilters, refresh: boolean) {
  const url = accessEndpoints.users.list;

  const params = {
    page,
    limit,
    advancedSearch: JSON.stringify(advancedSearch)
  }

  const { data, error, isLoading } = useCustomApiFetcher(
      url,
      params,
      `get`,
      refresh,
  );

  const memoizedValue = useMemo(
    (): IUserListResponse => ({
      userList: data ? data.data as IUser[] : [] as IUser[],
      userListCount: data ? data.count : {all: 0} as IUserCount,
      userListLoading: isLoading,
      userListError: error,
    }),
    [data, error, isLoading]
  );

  return { memoizedValue };
}

export function useGetRoleList() {
  const url = accessEndpoints.role.list;

  const { data, error, isLoading } = useCustomApiFetcher(
    url,
    {},
    `get`,
    true
  );

  const memoizedValue = useMemo(
    () => ({
      roleList: data ? data as IUserRole[] : [] as IUserRole[],
      roleListLoading: isLoading,
      roleListError: error,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export function useGetUserById(id: number | undefined) {
  const url = accessEndpoints.users.findById + `/${id}`;

  const { data, error, isLoading } = useCustomApiFetcher(
    url,
    {},
    `get`,
    true
);

  const memoizedValue = useMemo(
    () => ({
      currentUser: data ? data as IUserItem : undefined,
      currentUserLoading: isLoading,
      currentUserError: error,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export async function createNewUser(data: IUserSaveRequest): Promise<IUserItem> {
  const url = accessEndpoints.users.save;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data,
    responseType: `json`
  });
  
  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}

export async function getUserFromDocument(document: string): Promise<IUserItem> {
  const url = accessEndpoints.users.searchDocumentUser;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data: { document },
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}

export async function updateUser(data: IUserUpdateRequest): Promise<IUserItem> {
  const url = accessEndpoints.users.update + data?.id;

  const request: any = await axiosInstance.request({
    url: url,
    method: `put`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }

  return request.data;
}

export async function updateUserProfile(data: IUserProfileUpdateRequest): Promise<IUserItem> {
  const url = accessEndpoints.users.updateProfile + data?.id;

  const request: any = await axiosInstance.request({
    url: url,
    method: `put`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}

export async function removeUserFromOrganization(userId: number) {
  const url = accessEndpoints.organizations.removeUser;

  const body = {
    userId
  }
  let response;

  await axiosInstance.request({
    url: url,
    method: `post`,
    data: body,
    responseType: `json`
  }).then((data) => {
    response = data.data;
  });

  return response;
}

export function useAuthorizedUsersFromLocation(refresh: boolean, locationId?: any) {
  const url = accessEndpoints.users.findAuthorizedByLocation(locationId);

  const {
    data, isLoading, error
  } = useCustomApiFetcher(url, {}, 'get', refresh);

  const response = useMemo((): UsersListResponse => ({
    allUsers: data ? data.allUsers : [],
    usersAuthorized: data ? data.usersAuthorized : [],
  }), [data, isLoading, error, refresh]);

  return response;
}