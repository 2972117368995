import { Controller, useFormContext } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import applyMask from 'src/utils/applyMask';
import { applyCurrency } from 'src/utils/currencyUtils';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  masked?: boolean;
  mask?: string;
  currency?: boolean;
};

export default function RHFTextField({ 
  name, 
  currency = false,
  masked = false, 
  mask = '', 
  helperText, 
  type, 
  ...other 
}: Props) {
  const { control } = useFormContext();

  const checkMask = (value: string) => {
    let _value = value;

    if(!_value){
      return _value;
    }

    if(masked){
      return applyMask(_value, mask);
    }

    if(currency){
      return applyCurrency(_value); 
    }
    
    return _value;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : checkMask(field.value)}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(checkMask(event.target.value));
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
