import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export default function CircleLoading(){
    return (
        <Box
            sx={{
                px: 5,
                width: 1,
                flexGrow: 1,
                minHeight: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
        </Box>
    )
}