import { Badge, Box, IconButton, SxProps, badgeClasses } from "@mui/material";
import { Theme } from "@mui/system";
import { m } from "framer-motion";
import { useState } from "react";
import { varHover } from "src/components/animate";
import Iconify from "src/components/iconify/iconify";
import { useSettingsContext } from "src/components/settings";
import BaseOptions from "src/components/settings/drawer/base-option";

type Props = {
    sx?: SxProps<Theme>;
  };

export default function ThemeButton({ sx }: Props){
    const settings = useSettingsContext();
    const [themeMode, setThemeMode] = useState(settings.themeMode);

    return (
        <Box
            component={m.div}
            transition={{
                duration: 12,
                ease: 'linear',
                repeat: Infinity,
            }}
        >
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                aria-label="themeMode"
                onClick={() => {
                    if(themeMode === 'dark'){
                        setThemeMode('light')
                        settings.onUpdate('themeMode', 'light');
                        return;
                    }

                    setThemeMode('dark');
                    settings.onUpdate('themeMode', 'dark');
                }}
                sx={{
                    width: 40,
                    height: 40,
                }}
            >
                <Iconify 
                    icon={themeMode === 'dark' ? "solar:cloud-sun-bold" : "solar:sun-2-bold"} 
                    width={24} 
                />
            </IconButton>
        </Box>
    )
}