import { Helmet } from 'react-helmet-async';
import SelectOrganizationView from 'src/sections/auth/jwt/select-organization-view';

export default function SelectOrganizationPage() {
  return (
    <>
      <Helmet>
        <title>Zapizi - Escolher organização</title>
      </Helmet>

      <SelectOrganizationView />
    </>
  );
}
