import "src/global.css";

// i18n
import "src/locales/i18n";

// ----------------------------------------------------------------------

import 'moment/locale/pt-br';
import { useScrollToTop } from "src/hooks/use-scroll-to-top";
import Router from "src/routes/sections";

import { LocalizationProvider } from "src/locales";
import ThemeProvider from "src/theme";

import { MotionLazy } from "src/components/animate/motion-lazy";
import ProgressBar from "src/components/progress-bar";
import { SettingsDrawer, SettingsProvider } from "src/components/settings";
import SnackbarProvider from "src/components/snackbar/snackbar-provider";

import { CheckoutProvider } from "src/sections/checkout/context";

import { AuthProvider } from "src/auth/context/jwt";
import Version from "./components/version/version";

// ----------------------------------------------------------------------

export default function App() {
  const charAt = `
                                                                                          
                                                                  ███            ███        
          ██████████   ████                                       ███            ███        
          ██████████   ████     ████████    ████████  ████████    ███  ████████  ███        
          ██████████                  ██   █████████  ██████████  ███        ██  ███        
          ████████     ████         ███   ███    ███  ███    ████ ███      ███   ███        
          ██████   ████████       ████   ███     ███  ███     ███ ███    ███     ███        
                  █████████     ██       ████    ███  ██████████  ███  ██        ███        
          ████   ██████████     █████████   ██████    █████████   ███  ████████  ███        
          ████   ██████████                           ███                                   
                                                      ███                                   
                                                                                            
  
  `;

  console.info(`%c${charAt}`, "color: #5BE49B");

  console.info(`%cTodos os direiros reservados`, "color: #5BE49B");

  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: "dark", // 'light' | 'dark'
            themeDirection: "ltr", //  'rtl' | 'ltr'
            themeContrast: "default", // 'default' | 'bold'
            themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <CheckoutProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <Router />
                  <Version />
                </CheckoutProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
