import axiosInstance, { eventEndpoints } from "src/utils/axios";

export async function saveUserToken(body: SaveContactFirebaseTokenRequest) {
    const url = eventEndpoints.contact.saveToken;
    
    const request: any = await axiosInstance.request({
      url: url,
      method: `post`,
      data: body,
      responseType: `json`
    });
    
    const res = request?.response;
    
    return res;
  }


export type SaveContactFirebaseTokenRequest = {
    token: string;
    userId: number;
}