import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import packageJson from "../../../package.json";

export default function Version(){
    return (
        <Stack
            zIndex={99}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            position={"fixed"}
            bottom={2}
            right={10}
        >
            
            <Typography variant="subtitle2">
                v{packageJson.version}
            </Typography>
        </Stack>
    )
}