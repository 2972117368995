import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useRouter, useSearchParams } from "src/routes/hooks";

import { useBoolean } from "src/hooks/use-boolean";

import { useAuthContext } from "src/auth/hooks";
import { ADMIN_PATH_AFTER_LOGIN } from "src/config-global";

import { useContractContext } from "src/auth/context/contract/contract-context";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import { USER_ROLES } from "src/types/permissions";

export default function JwtLoginView() {
  const { 
    user, 
    organizations, 
    login, 
    logout, 
    listOrganization, 
    checkDefaultOrganization,
    role,
    selectOrganization,
    removeRedirectTo,
    redirectTo,
    selectedOrganization
  } = useAuthContext();

  const { getContract } = useContractContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState<string | undefined>("");

  //TODO: Reimplementar
  const searchParams = useSearchParams();

  const password = useBoolean();

  const needToRedirect = async () => {
    const returnTo = sessionStorage.getItem("returnTo");
    let url;
    let organizationId: string | null = "";
    let organization;

    if(returnTo){
      url = new URL(returnTo);
      organizationId = url.searchParams.get("organizationId");
    }

    if(organizationId && organizationId != "") {
      organization = organizations?.filter((organization) => organization.id === Number(organizationId))[0];
    }

    if(user && organization && url){
      await selectOrganization(organization);
      await removeRedirectTo();
      router.push(`${url.pathname}?${url.searchParams}`);
      return true;
    }

    return false
  };

  const initialize = async () => {
    try {

      const need = await needToRedirect();

      if(need){
        return;
      }

      if (user) {
        await listOrganization?.();
      }

      if (organizations) {
        const redirectUrl = await checkDefaultOrganization?.();
        
        if(role == USER_ROLES.ADMIN || role == USER_ROLES.MANAGER){
          router.replace(redirectUrl || ADMIN_PATH_AFTER_LOGIN);
        }

        router.replace(redirectUrl || ADMIN_PATH_AFTER_LOGIN);
      }
    } catch (error) {
      logout();
      reset();
      setErrorMsg(typeof error === "string" ? error : error.message);
    }
  }

  useEffect(() => {
    initialize();
  }, [user, organizations]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("O e-mail é obrigatório"),
    password: Yup.string().required("A senha é obrigatória"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const user = await login?.(data.email, data.password);

    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === "string" ? error : error.message);
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Entrar no painel</Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>

      <RHFTextField name="email" label="Email ou usuário" />

      <RHFTextField
        name="password"
        label="Sua senha"
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: "flex-end" }}
      >
        Esqueceu a senha?
      </Link>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Entrar
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}
      {renderForm}
    </FormProvider>
  );
}
