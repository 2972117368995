export type IStatementListResponse = {
    statementsList: StatementDatedObject[],
    isLoading: boolean,
    error: any,
    indicators: IStatementIndicators,
    count: IStatementCount
}

export type IStatementCount = {
    pagination: number;
};

export type IStatementIndicators = {
    avalible: number;
    future: number;
    revenue: number;
    redeemed: number;
    refunded: number;
};

export type StatementIndicatorInfo = {
    indicator: StatementIndicatorsEnum,
    value: number 
}

export type StatementDatedObject = {
    date: string,
    liquidRevenue: number,
    revenue: number,
    refunded: number,
    redeemed: number,
    tax: number,
    lastBalance: number
}

export type Statement = {
    date: Date,
    id: number,
    description: string,
    status: StatementStatus,
    value: number,
    actualBalance: number,
}

export enum StatementIndicatorsEnum {
    AVALIBLE = "AVALIBLE",
    FUTURE = "FUTURE",
} 
export enum StatementStatus {
    ALL = "ALL",
    PAID = "PAID",
    PENDING = "PENDING",
    REFUNDED = "REFUNDED",
    CREATED = "CREATED",
} 

export enum StatementType {
    ALL = "ALL",
    SALE = "SALE",
    REFUND = "REFUND",
    CREDIT = "CREDIT",
    SALE_TAX = "SALE_TAX",
}

export type IStatementTableFilterValue = string | Date | null;

export type IStatementFilters = {
    search: string | null;
    startDate: Date | null;
    endDate: Date | null;
};

export enum PixKeyTypeEnum {
    EMAIL = "EMAIL",
    DOCUMENT = "DOCUMENT",
    PHONE = "PHONE",
    RANDOM = "RANDOM",
}

export type PixKeyResponse =  {
    key?: string,
    id?: number,
    keyType?: PixKeyTypeEnum,
    isLoading?: boolean,
    error?: any,
}

export type PixKeyRequest = {
    key: string,
    keyType: PixKeyTypeEnum,
}

export const DefaultPixKeyResponse: PixKeyResponse = {
    key: "",
    id: 0,
    keyType: PixKeyTypeEnum.RANDOM,
    isLoading: false,
    error: null,
}


export interface PixKeyOption {
    label: string;
    icon: string;
    type: PixKeyTypeEnum;
}