import { lazy, Suspense } from "react";
import { Outlet } from "react-router";
import { AuthGuard } from "src/auth/guard";
import { LoadingScreen } from "src/components/loading-screen";
import ContractLayout from "src/layouts/contract";
import { paths } from "../paths";

const SignContractPage = lazy(() => import('src/pages/dashboard/contract/sign'));
const ContractNeedSignPage = lazy(() => import('src/pages/dashboard/contract/need-sign'));

export const contractRoutes = [
    {
        path: paths.contract.root,
        element: (
            <AuthGuard>
                <ContractLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </ContractLayout>
            </AuthGuard>
        ),
        children: [
            { path: 'sign', element: <SignContractPage /> },
            { path: 'need-sign', element: <ContractNeedSignPage /> }
        ]
    }
]