import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { useBoolean } from "src/hooks/use-boolean";

type Props = {
    open: boolean;
}

export default function NeedSignContractDialog({ open }: Props){

    const opened = useBoolean(open);

    useEffect(() => {
        opened.setValue(open);
    }, [open])

    return (
        <Dialog open={opened.value} id={"need-sign-contract-dialog"} fullWidth>
            <DialogTitle>
                Assinatura de contrato
            </DialogTitle>
            <DialogContent>
                <div>
                    <p>
                        A assinatura dos termos de uso deve ser realizada pelo administrador da sua organização. 
                        Por favor, notifique o administrador para que ele conclua essa etapa.
                    </p>
                    <p>
                        Caso tenha dúvidas, entre em contato com o suporte da sua organização.
                    </p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="contained" 
                    onClick={() => {
                        opened.setValue(false);
                    }}
                >
                    Entendi
                </Button>
            </DialogActions>
        </Dialog>
    );
}