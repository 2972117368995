import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useBoolean } from "src/hooks/use-boolean";
import axiosInstance, { accessEndpoints } from "src/utils/axios";

type Props = {
    open: boolean;
    url: string;
}

export default function SignContractDialog({ open, url }: Props){

    const { enqueueSnackbar } = useSnackbar();

    const accepted = useBoolean();

    const opened = useBoolean(open);

    useEffect(() => {
        opened.setValue(open);
    }, [open]);

    const handleSubmit = useCallback(async () => {
        try {
            const res = await axiosInstance.post(accessEndpoints.organization.signContract, {
                signed: accepted.value
            });

            if(res && res.status == 200 && res.data){
                enqueueSnackbar("Contrato assinado com sucesso!");
            } else {
                enqueueSnackbar("A assinatura dos termos é obrigatória!", { variant: "error" });
            }

            opened.setValue(false);
        } catch (error) {
            console.log(error);
        }
    }, [accepted])

    return (
        <Dialog open={opened.value} id={"sign-contract-dialog"} fullWidth>
            <DialogTitle>
                Assinatura de contrato
            </DialogTitle>
            <DialogContent>
                <p>
                    Ao utilizar os serviços da Zapizi, você concorda com os seguintes termos:
                </p>
                <ul>
                    <li>
                        <strong>Funcionamento:</strong> As máquinas de venda automática são conectadas ao nosso sistema via internet. Após o pagamento via PIX, o produto é entregue automaticamente.
                    </li>
                    <li>
                        <strong>Cadastro:</strong> É necessário criar uma conta em nosso site, podendo ser solicitados documentos adicionais para validação.
                    </li>
                    <li>
                        <strong>Entrega de Valores:</strong> Os valores das vendas são depositados em uma conta virtual e transferidos para sua conta bancária no próximo dia útil.
                    </li>
                    <li>
                        <strong>Remuneração:</strong> Taxas e valores de acordo com os descritos nos 
                        <Link
                            href={url}
                            variant="body1"
                            target="_blank"
                            rel="noopener"
                        >
                            &nbsp; termos de uso
                        </Link>.
                    </li>
                    <li>
                        <strong>Responsabilidades:</strong> A conexão à internet e o funcionamento correto do seu equipamento são de sua responsabilidade, assim como a conformidade com as leis de proteção ao consumidor.
                    </li>
                    <li>
                        <strong>Cancelamento do Serviço:</strong> O contrato é por tempo indeterminado, podendo ser cancelado por qualquer parte com 30 dias de antecedência.
                    </li>
                </ul>
                <div>
                    Para acessar os termos de uso, 
                    <Link 
                        href={url} 
                        variant="body1" 
                        target="_blank" 
                        rel="noopener"
                    >
                        &nbsp; clique aqui
                    </Link>
                </div>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: "space-between"
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                    <Checkbox 
                        value={false} 
                        onChange={(e: any) => {
                            console.log(e);
                            accepted.setValue(e.target.checked);
                        }}
                    />
                    <Typography variant="subtitle2">
                        Aceito os termos de uso propostos pelo documento
                    </Typography>
                </Stack>
                <Stack 
                    direction="row" 
                    justifyContent="space-between" 
                    alignItems={"center"}
                    gap={"12px"}
                >
                    <Button 
                        variant="text" 
                        onClick={() => {
                            opened.setValue(false);
                        }}
                    >
                        Depois
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleSubmit}
                    >
                        Assinar
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}