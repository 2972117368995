import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { MessagePayload } from '@firebase/messaging/sw';
import { sendNativeNotification, toastNotification } from './notificationHelpers';

const firebaseConfig = {
  apiKey: "AIzaSyCcNxBPr7myM4uPE680TmscE_O2QHsEE08",
  authDomain: "zapizi-pos.firebaseapp.com",
  projectId: "zapizi-pos",
  storageBucket: "zapizi-pos.appspot.com",
  messagingSenderId: "958540588328",
  appId: "1:958540588328:web:023034ca50d1a7b379dc1d",
  measurementId: "G-73DLHB8FWV"
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

const setupNotifications = async (
  isForeground: boolean, 
  enqueueSnackbar: any, 
  closeSnackbar: any,
  saveToken: any,
  router: any
) => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get the FCM token
      const token = await getToken(messaging);
      console.log('FCM Token:', token);

      await saveToken({
        token,
        userId: 4
      })
      
    } else {
      console.log('Notification permission denied.');
    }
    // Handle foreground notifications
    onMessage(messaging, (payload: MessagePayload) => {
      console.log('Foreground Message:', payload);
      
      if (isForeground) {
        toastNotification(payload, enqueueSnackbar, closeSnackbar, router);
        sendNativeNotification(payload);
      }
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
export { messaging, setupNotifications };
