import { ITableOrderBy } from "./table-util";

export type IOrganization = {
    id: number;
    created: Date;
    updated: Date;
    active: boolean;
    name: string;
    description: string;
    logoPath: string;
    cpfCnpj: string;
    phone: string;
    email: string;
    whatsappNumber: string;
}

export enum OrganizationStatus {
    PENDING = "PENDING",
    ALL = "ALL"
  }

export type ISaasOrganization = {
    id: number;
    created: Date;
    updated: Date;
    active: boolean;
    name: string;
    description: string;
    logoPath: string;
    cpfCnpj: string;
    phone: string;
    email: string;
    whatsappNumber: string;
    pendingContract: boolean;

    plan?: {
        id: number;
        name: string;
    }

    credPreferences?: {
        id: number
    }

    pixInfo: {
        key: string,
        keyType: string
    }
}

export type IOrganizationRequest = {
    id?: number;
    name: string;
    description: string;
    email: string;
    logoPath: string;
    cpfCnpj: string;
    phone: string;
    whatsappNumber: string;
    active: boolean;

    redemptionOptionId: number;

    pixKey: string;
    pixKeyType: string | undefined;

    planId: number;
}

export const DefaultOrganization = {
    id: 0,
    created: new Date(),
    updated: new Date(),
    active: false,
    name: '',
    description: '',
    logoPath: '',
    cpfCnpj: '',
    phone: '',
    email: '',
    whatsappNumber: '',
}

export type FindOrganizationResponse = {
    organization: ISaasOrganization;
    isLoading: Boolean;
    error: any;
}

export type FindSaasOrganizationResponse = {
    organization: ISaasOrganization;
    isLoading: Boolean;
    error: any;
}

export type FindSaasOrganizationContractResponse = {
    currentContract: ISaasOrganization;
    pendingContract: ISaasOrganization | undefined;
    isLoading: Boolean;
    error: any;
}

export type OrganizationListAdvancedSearch = {
    search: string;
    status: OrganizationStatus;
    orderBy?: ITableOrderBy | undefined;
}

export type OrganizationListData = {
    list: ISaasOrganization[];
    isLoading: boolean;
    error: any;
    count: OrganizationListCount;
}

export type OrganizationListCount = {
    all: number;
    pagination: number;
    pending: number;
}