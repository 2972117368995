import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';
import AdministrativeLayout from 'src/layouts/admin';
import { paths } from '../paths';

const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const CreateOrganizationPage = lazy(() => import('src/pages/admin/saasOrganization/create'));
const SaasOrganizationEditPage = lazy(() => import('src/pages/admin/saasOrganization/edit'));
const SaasOrganizationContractPage = lazy(() => import('src/pages/admin/saasOrganization/contract'));
const SaasOrganizationListPage = lazy(() => import('src/pages/admin/saasOrganization/list'));
//Consolidation
const ConsolidationListPage = lazy(() => import('src/pages/dashboard/consolidation/list'));
//Saas-Statement
const SaasStatementListPage = lazy(() => import('src/pages/dashboard/saas-statement/list'));
//Saas-machine
const SaasMachineListPage = lazy(() => import('src/pages/admin/saasMachine/list'));
const SaasMachineEditPage = lazy(() => import('src/pages/admin/saasMachine/edit'));
//Saas-sales
const SaasSalesListPage = lazy(() => import('src/pages/admin/saasSales/list'));
const SalesDetailsPage = lazy(() => import('src/pages/dashboard/sales/details'));

export const adminDashboardRoutes = [
  {
    path: paths.dashboard.rootAdmin,
    element: (
      <AuthGuard>
        <AdministrativeLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AdministrativeLayout>
      </AuthGuard>
    ),
    children: [
      { element: <BlankPage />, index: true },
      { path: 'blank', element: <BlankPage /> },
      { 
        path: 'saas-organization', 
        children: [
          { element: <SaasOrganizationListPage/>, index: true},
          { path: 'list', element: <SaasOrganizationListPage/>},
          { path: 'create', element: <CreateOrganizationPage/> },
          { path: ':id/edit', element: <SaasOrganizationEditPage/> },
          { path: ':id/contract', element: <SaasOrganizationContractPage/> },
        ]
      },
      {
        path: 'consolidation',
        children: [
          { element: <ConsolidationListPage />, index: true },
          { path: 'list', element: <ConsolidationListPage /> }
        ],
      },
      {
        path: 'saas-statement',
        children: [
          { element: <SaasStatementListPage/>, index: true },
          { path: 'list', element: <SaasStatementListPage/> }
        ],
      },
      {
        path: 'saas-machine',
        children: [
          { element: <SaasMachineListPage/>, index: true },
          { path: 'list', element: <SaasMachineListPage/> },
          { path: ':id/edit', element: <SaasMachineEditPage/> },
        ],
      },
      {
        path: 'saas-sales',
        children: [
          { element: <SaasSalesListPage/>, index: true },
          { path: 'list', element: <SaasSalesListPage/> },
          { path: ':id', element: <SalesDetailsPage/> },
        ],
      },
    ],
  },
];
