import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';


import { hideScroll } from 'src/theme/css';

import { AdminLogo, OrganizationLogo } from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';

import { useAuthContext } from 'src/auth/hooks';
import { useSettingsContext } from 'src/components/settings';
import NavToggleButton from '../common/nav-toggle-button';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user, role, permissions } = useAuthContext();

  const navData = useNavData();

  const { themeMode } = useSettingsContext();

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pt: 2,
          pb: 2,
          height: 1,
          position: 'fixed',  
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >

        <OrganizationLogo/>

        <NavSectionMini
          data={navData}
          slotProps={{
            currentRole: role,
            currentPermissions: permissions,
            isAdmin: user?.isAdministrator
          }}
        />

        <Box sx={{ flexGrow: 1 }} />
        
        <AdminLogo 
          themeMode={themeMode}
        />
      </Stack>
    </Box>
  );
}
