import { lazy } from 'react';
import { Outlet } from 'react-router';
import CouponLayout from 'src/layouts/coupon';
import { paths } from '../paths';

const CouponPage = lazy(() => import('src/pages/coupon/principal'));
const CouponNotFoundPage = lazy(() => import('src/pages/coupon/not-found'));
const CouponSuccessPage = lazy(() => import('src/pages/coupon/success'));
const CouponErrorPage = lazy(() => import('src/pages/coupon/error'));

// ----------------------------------------------------------------------

export const couponRoutes = [
  {
    path: paths.coupon.root,
    element: (
      <CouponLayout>
        <Outlet/>
      </CouponLayout>
    ),
    children: [
      { element: <CouponPage />, index: true },
      { path: 'not-found', element: <CouponNotFoundPage /> },
      { path: 'success', element: <CouponSuccessPage /> },
      { path: 'error', element: <CouponErrorPage /> },
      { path: ':referenceId', element: <CouponPage /> }, 
    ],
  },
];
