
import { ILoginRequest, ILoginResponse, IUpdateTokenRequest } from 'src/types/auth';
import axiosInstance, { accessEndpoints } from 'src/utils/axios';

export async function loginRequest(data: ILoginRequest): Promise<ILoginResponse> {
  const url = accessEndpoints.auth.login;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}

export async function updateTokenRequest(data: IUpdateTokenRequest): Promise<ILoginResponse> {
  const url = accessEndpoints.auth.refresh;

  const request: any = await axiosInstance.request({
    url: url,
    method: `post`,
    data,
    responseType: `json`
  });

  if(request.status >= 400){
    throw(request.data);
  }
  
  return request.data;
}