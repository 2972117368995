import { forwardRef, useEffect } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { useAuthContext } from 'src/auth/hooks';
import { LOGO_BASE_URL } from 'src/config-global';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface AdminLogoProps extends BoxProps {
  disabledLink?: boolean;
  themeMode?: "light" | "dark";
  themeLayout?: "horizontal" | "vertical" | "mini";
}

const LogoBase = LOGO_BASE_URL;

const LogoWidth = {
  "horizontal": 80,
  "mini": 80,
  "vertical": 180,
}

const LogoPathsVariation = {
  "vertical": {
    "light": "/zapizi_hor_blue.svg",
    "dark": "/zapizi_hor_white.svg"
  },
  "mini": {
    "light": "/zapizi_blue.svg",
    "dark": "/zapizi_white.svg",
  },
  "horizontal": {
    "light": "/zapizi_blue.svg",
    "dark": "/zapizi_white.svg",
  }
}

const AdminLogo = forwardRef<HTMLDivElement, AdminLogoProps>(
  ({ disabledLink = false, themeMode: mode = "light", themeLayout = "horizontal", sx, ...other }, ref) => {
    const theme = useTheme();

    const { selectedOrganization } = useAuthContext();

    const handleGetLogoPathVariation = () => {      
      return LogoBase + LogoPathsVariation[themeLayout][mode]
    };

    useEffect(() => {}, [selectedOrganization]);

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: LogoWidth[themeLayout],
          height: 50,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <img src={handleGetLogoPathVariation()} alt="" />
      </Box>
    );

    if (disabledLink) {
      return logo;
    } 

    return (
      <Link component={RouterLink} href={selectedOrganization ? "/list-organization" : "/dashboard"} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default AdminLogo;
