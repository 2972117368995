import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { m } from "framer-motion";
import { useCallback, useEffect } from "react";
import { varHover } from "src/components/animate";
import Iconify from "src/components/iconify";
import { useBoolean } from "src/hooks/use-boolean";

export default function NotificationButton(){
    const permission = useBoolean();
    // const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        notificationsPermission().then((value) => {
            permission.setValue(value);
        });
    }, []);

    const notificationsPermission = useCallback(async () => {
        const permissionRequest = await Notification.requestPermission();
        const permission = permissionRequest === "granted";

        // TODO: Verificar melhor como desabilitar
        // if(permission){
        //     register();
        // } else {
        //     unregister(enqueueSnackbar);
        // }

        return permission;
    }, []);

    return (
        <Box
            component={m.div}
            transition={{
                duration: 12,
                ease: 'linear',
                repeat: Infinity,
            }}
        >
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                aria-label="themeMode"
                onClick={() => {
                    notificationsPermission().then((value: boolean) => {
                        if(value){

                        }
                        permission.setValue(value);
                    });
                }}
            >
                <Iconify 
                    icon={permission.value ? "mdi:bell" : "mdi:bell-off"}
                    width={18}
                />
            </IconButton>
        </Box>
    );
}