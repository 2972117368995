import { Avatar, Link, Stack, Typography } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import { forwardRef, useEffect } from "react";
import { useAuthContext } from "src/auth/hooks";
import { RouterLink } from "src/routes/components";
import { useSettingsContext } from "../settings";

// ----------------------------------------------------------------------

export interface OrganizationLogoProps extends BoxProps {
    disabledLink?: boolean;
    themeMode?: "light" | "dark";
    themeLayout?: "horizontal" | "vertical" | "mini";
  }

const OrganizationLogo = forwardRef<HTMLDivElement, OrganizationLogoProps>(
    ({ disabledLink = false, themeMode: mode = "light", themeLayout = "horizontal", sx, ...other }, ref) => {

      const settings = useSettingsContext();

      const isMini = settings.themeLayout === 'mini';

      const { selectedOrganization } = useAuthContext();

      useEffect(() => {}, [selectedOrganization]);

      const logo = (
        <Box
          ref={ref}
          component="div"
          sx={{
            display: 'flex',
            width: '100%',
            padding: '16px 24px',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
          {...other}
        >
          <Stack
            alignItems="center"
            flexDirection="row"
            width="100%"
            gap="16px"
          >
            <Avatar 
              alt={selectedOrganization?.name} 
              src={selectedOrganization?.logoPath} 
            />
            { !isMini &&
              <Stack
                sx={{
                  color: 'white',
                  width: '180px'
                }}
              >
                <Typography variant="h6" noWrap>
                  {selectedOrganization?.name}
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ color: 'text.secondary' }} 
                  noWrap
                >
                  {selectedOrganization?.description}
                </Typography>
              </Stack>
            }
          </Stack>
        </Box>
      );
  
      if (disabledLink) {
        return logo;
      } 
  
      return (
        <Link component={RouterLink} href={selectedOrganization ? "/dashboard" : "/list-organization"} sx={{ display: 'contents' }}>
          {logo}
        </Link>
      );
    }
  );

export default OrganizationLogo