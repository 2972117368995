import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import { useBoolean } from "src/hooks/use-boolean";

type Props = {
    open: boolean;
    onClose: () => void;
}

export default function NotificationHelperDialog({
    open,
    onClose
}: Props){
    const openDialog = useBoolean(open);

    useEffect(() => {
        openDialog.setValue(open);
    }, [open])

    return (
        <Dialog open={openDialog.value} onClose={onClose}>
            <DialogTitle>
                Como ativar as notificações.
            </DialogTitle>
            <DialogContent>
                <Stack>
                    <Typography variant="subtitle1">
                        Ativição de notificações
                    </Typography>
                    <p>
                        Esta configuração se aplica à exibição de notificações no navegador. 
                    </p>
                    <ol>
                        <li>Acesse sua conta no Portal Zapizi;</li>
                        <li>Clique em sua foto de usuário, localizado no canto superior direito da página;</li>
                        <li>No menu suspenso que será exibido, clique em Perfil do usuário;</li>
                        <li>Clique no botão Permissão das notificações;</li>
                        <li>Será exibida uma confirmação no navegador. Clique no botão Permitir;</li>
                    </ol>
                    <img 
                        src="/assets/images/other/notification-active.png" 
                        alt="Notifcações ativas"
                        style={{
                            width: 280,
                            height: 400,
                            marginTop: '40px'
                        }} 
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => onClose()}>
                    Entendi
                </Button>
            </DialogActions>
        </Dialog>
    )
}