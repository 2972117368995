import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { BASE_API } from "src/config-global";
import axiosInstance from "./axios";

export type Props = {
    url: string, 
    params: any, 
    method: string, 
    refresh: boolean
}

type CustomFetcherProps = {
    url: string; 
    params: any; 
    method: string; 
    refresh: boolean;
    axiosInstance: any;
}

export const useCustomFetcher = ({
    params,
    method,
    refresh,
    url,
    axiosInstance,
}: CustomFetcherProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [error, setError] = useState<any>();

    const paramsProps = Object.keys(params).map(key => params[key]);

    useEffect(() => {
        setIsLoading(true);
        axiosInstance(url, {
            method, 
            params
        }).then(async (response: AxiosResponse) => {
            const data = await response.data;
            setIsLoading(false);
            setData(data);
        }).catch(async (error: any) => {
            await setError(error);
        });
    }, [refresh, ...paramsProps, url]);

    return { data, isLoading, error };
};

export const useCustomApiFetcher = (
    url: string, 
    params: any, 
    method: string, 
    refresh: boolean,
) => useCustomFetcher({
    url,
    method,
    params,
    refresh,
    axiosInstance: axiosInstance,
});

export const usePublicCustomFecther = (
    url: string, 
    params: any, 
    method: string, 
    refresh: boolean,
) => {
    
    const axiosPublicInstance = axios.create({ baseURL: BASE_API });

    return useCustomFetcher({
        url,
        params,
        method,
        refresh,
        axiosInstance: axiosPublicInstance,
    });
};