import { MessagePayload } from "@firebase/messaging/sw";
import { Stack } from "@mui/material";
import Iconify from "../iconify";

export const toastNotification = (notification: MessagePayload, enqueueSnackbar: any, closeSnackbar: any, router: any) => {
  const notificationData = notification.data;
  console.log(`Toast Notification: ${notification.data?.title} - ${notification.data?.message} - ${notification.notification?.image}`);
  
  enqueueSnackbar(notificationData?.title + "..." ?? "Notificação", { 
    variant: "info",
    action: () => {
      return (
        <Stack 
          direction={"row"}
          spacing={2}
        >
          <Iconify 
            icon="solar:eye-broken"
            style={{
              cursor: "pointer"
            }}
            onClick={() => router.push(`/dashboard/notifications?eventId=${notification?.data?.eventId}`)}
          />
          <Iconify 
            icon="iconamoon:close" 
            onClick={() => closeSnackbar()}
            style={{
              cursor: "pointer"
            }}
          />
        </Stack>
      );
    }
  });
};

export const sendNativeNotification = (notification: MessagePayload) => {
  const notificationData = notification;
  NotificationDemo(notification);
};

const NotificationDemo =  async ({ notification, data }: MessagePayload) => {
    console.log("notificação nativa");
    const permission = await Notification.requestPermission();
    console.log(permission);
    if ('Notification' in window) { 
      // Verificar se as notificações estão permitidas
      if (permission === 'granted') {
        console.log(notification)
        // Criar e exibir a notificação
        const notify = new Notification(data?.title || "Zapizi", {
          body: data?.message,
          icon: data?.image,
          badge: data?.image,
        });

        notify.onclick = () => {
          window.open(`/dashboard/notifications?eventId=${data?.eventId}`);
        }
        
        return notify;
      } else if (Notification.permission !== 'denied') {
        // Pedir permissão ao usuário para exibir notificações
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            // Criar e exibir a notificação
            new Notification('Notificação de Exemplo', {
              body: 'Esta é uma notificação de exemplo!',
              icon: '/path/to/icon.png', // Caminho para o ícone da notificação (opcional)
              requireInteraction: true
            });
          }
        });

      }
    }
};