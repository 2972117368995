import { createContext, useContext } from 'react';

import { ContractContextType } from 'src/types/contract';

// ----------------------------------------------------------------------

export const ContractContext = createContext({} as ContractContextType);

export const useContractContext = () => {
  const context = useContext(ContractContext);

  if (!context) throw new Error('useContractContext must be use inside ContractContext');

  return context;
};
