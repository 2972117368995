import validates from "./validates";

export function IndentifyPixKeyType (key: string){
    let value: string = key;
    
    if (value && value.match(CPF_REGEX) && validates.validateCpf(value).valid) {
        return "cpf";
    }

    if (value && value.match(CNPJ_REGEX) && validates.validateCnpj(value).valid) {
        return "cnpj";
    }

    if (value && value.match(PHONE_REGEX)) {
        return "phone";
    }

    if (value && value.match(EMAIL_REGEX)) {
        return "email";
    }

    if (value && value.match(KEY_REGEX)) {
        return "key";
    }

    return "key";
}

const CPF_REGEX = /^[0-9]{11}$/;
const CNPJ_REGEX = /^[0-9]{14}$/;
const PHONE_REGEX = /^\+[1-9][0-9]\d{1,11}$/;
// const PHONE_REGEX = /^[0-9]{10,11}$/;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const KEY_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
