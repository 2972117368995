import { RequestPresignedURL } from "src/types/aws";
import axios, { accessEndpoints } from "src/utils/axios";

export async function getPresignedURL({ 
    fileName, imageEntity
 }: RequestPresignedURL) {
    const params = new URLSearchParams({
        fileName,
        imageEntity
    });
    
    const url = accessEndpoints.aws.presignedUrl;

    const { logoPath, preSignedUrl } = await axios.get(url, {
        params,
    }).then((response) => {
        return response.data;
    });

    return {
        logoPath,
        preSignedUrl
    };
}

export async function uploadImage(file: File, url: string) {
    const response = await fetch(`${url}`, {
        method: "PUT",
        headers: {
            "Content-Type": "*",
        },
        body: file,
    });

    if(response.status !== 200) {
        throw new Error('Erro ao fazer upload da imagem');
    }

    return;
}